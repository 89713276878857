// For sticky footer
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#content {
  flex: 1;
}


#footer {
  background-color: transparent;
  text-align: left;
  padding: 0;
  margin: 0;

  .widget h2 {
    margin-bottom: 12px;
  }
}

#footer_widgets {
  color: $black;
  background-color: $grey;
  text-align: left;
  padding: 10px 0;
  margin: 0;
  min-height: 220px;
}

#footer_legal {
  padding: 12px 0 4px 0;
  color: $white;
  text-align: center;
  background-color: $spd_red;

  > * {
    margin: 10px 0;
  }

  .container {
    text-align: center;
    width: 100%;

    .row {
      margin: 0 auto;
      width: 100%;
    }
  }

  a:link, a:visited, a:active, a:hover {
    color: $white;
  }

  a:focus {
    color: $white;
  }

  a:hover {
    text-decoration: underline;
  }
}

.navbar-nav {
  clear: left;
}

.navbar-header {
  text-align: center;
}
