@media (min-width: 768px) {
  nav.navbar {
    height: 220px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;

    > *:first-child {
      transition: margin 400ms ease;
      margin-top: 0;
    }

    &.shrink > *:first-child {
      margin-top: -170px;
    }
    
    &.shrink {
      height: 50px;

      .navbar-header {
	visibility: hidden;
      }
    }
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    float:none;
    margin:0 auto;
    display: block;
    text-align: center;

    > li {
      display: inline-block;
      float:none;
    }
  }

  .navbar-header {
    padding: 0;
    float: none;

    .navbar-brand {
      float: none;
      padding-top: 5px;
      padding-bottom: 0;
      margin: 0;

      img {
        margin: -8px auto 12px auto;
      }
    }
  }

  .navbar-default .navbar-brand span {
    display: block;
    margin-bottom: -16px;
    color: #777;
  }
}

@media (max-width: 767px) {
  .navbar {
    .navbar-header {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
    }

    button.navbar-toggle {
      flex: 0 1 auto;
      float: none;
    }

    .navbar-brand {
      flex: 1 1 auto;
      float: none;
      text-align: left;
      height: auto;

      img {
        position: absolute;

        opacity: 1.0;
        transition: opacity 0.4s ease;

        &.collapse.in {
          display: none;
        }
        &.collapse {
          display: block;
          height: auto !important;
        }

        top: 15px;
        left: 15px;
        width: 80px;
      }

      span {
        display: block;
        margin-left: 95px;
        margin-bottom: -2px;
        text-align: left;
        line-height: 135%;
      }
    }

    &.shrink {
      .navbar-brand img {
        opacity: 0.0;
      }  
    }
  }
}