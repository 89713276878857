table.em-calendar td.eventful a {
  background-color: $spd_red;
  border-radius: 2px;
  color: #fff;
  padding: 2px 3px;
}

table.em-calendar td.eventless-today,
table.em-calendar td.eventful-today {
  border-radius: 2px;
}
