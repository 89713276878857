
aside.layout-sidebar {
  margin-top: 60px;

  & > li {
    list-style-type: none;

    &.widget_recent_entries li {
      list-style-type: none;
    }
  }

  .widget {
    margin-bottom: 10px;
    padding: 5px;
    clear: both;

    h1, h2, h3 {
      margin-top: 5px;
    }

    ul {
      padding-left: 0;

      li {
        margin-bottom: .5em;
      }
    }

    .wp-video {
      overflow: hidden;
    }
  }
}
